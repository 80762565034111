import Navbar from "./components/app-components/layout-components/Navbar";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import BaseTheme from "./shared/BaseTheme";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import Login from "./components/app-components/auth-components/Login";
import Home from "./components/app-components/pages-components/Home";
import About from "./components/app-components/pages-components/About";
import Register from "./components/app-components/auth-components/Register";

import EditContact from "./components/contacts-component/EditContact";
import ContactsManager from "./components/contacts-component/ContactsManager";
import Snackbar from "./components/app-components/shared-components/Snackbar";
import { useSelector } from "react-redux";
import CManager from "./components/contacts-component/C-Manager";
import AddContact from "./components/contacts-component/AddContact";
import ViewContact from "./components/contacts-component/ViewContact";

const ProtectedRoute = ({ redirectPath = "/login" }) => {
  const { user } = useSelector((state) => state.auth);
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

function App() {
  return (
    <>
      <ThemeProvider theme={BaseTheme}>
        <CssBaseline />
        <Snackbar />
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/contacts/edit/:id" element={<EditContact />} />
            <Route path="/contacts/view/:id" element={<ViewContact />} />
            <Route path="/contacts/add" element={<AddContact />} />
            <Route path="/contacts" element={<CManager />} />
            <Route path="/about" element={<About />} />
          </Route>

          {/*<Route path="/contacts" element={<ContactsManager />} />*/}

          <Route path="/register" element={<Register />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
