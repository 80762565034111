import * as muc from "../../../shared/MUI-Shared-Imports";
import * as mui from "../../../shared/MUI-Shared-Icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { register, auth_reset } from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

const useStyles = muc.makeStyles(() => ({
  input1: {
    height: 17,
  },
}));
const Register = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    password2: "",
  });
  const { firstname, lastname, email, password, password2 } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      console.error(message);
    }

    if (isSuccess || user) {
      navigate("/");
    }

    dispatch(auth_reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      console.error("Passwords do not match");
    } else {
      const userData = {
        firstname,
        lastname,
        email,
        password,
      };

      dispatch(register(userData));
    }
  };

  if (isLoading) {
    return <muc.CircularProgress />;
  }

  return (
    <div>
      <muc.Grid
        style={{ padding: "50px 15px 0 15px", maxWidth: 550, margin: "0 auto" }}
      >
        <muc.Grid container spacing={1}>
          <muc.Grid item xs={12} align="center" mb={2}>
            <mui.HowToRegIcon
              color={"primary"}
              style={{ width: "70px", height: "70px" }}
            />
            <muc.Typography variant="h5" color="error">
              Register
            </muc.Typography>
          </muc.Grid>
        </muc.Grid>

        <form onSubmit={onSubmit}>
          <muc.Grid
            container
            spacing={2}
            sx={{
              "& .MuiOutlinedInput-root:hover": {
                "& > fieldset": {
                  borderColor: "orange",
                },
              },
            }}
          >
            <muc.Grid xs={12} sm={12} item>
              <muc.TextField
                id={"firstname"}
                name="firstname"
                value={firstname}
                onChange={onChange}
                type="text"
                label="First Name"
                placeholder="Enter first name"
                fullWidth
                required
                variant={"outlined"}
                InputProps={{
                  classes: { input: classes.input1 },
                  endAdornment: (
                    <muc.InputAdornment position="end">
                      <mui.PersonIcon color={"secondary"} />
                    </muc.InputAdornment>
                  ),
                }}
              />
            </muc.Grid>
            <muc.Grid xs={12} sm={12} item>
              <muc.TextField
                id="lastname"
                name="lastname"
                value={lastname}
                onChange={onChange}
                type="text"
                label="Last Name"
                placeholder="Enter last name"
                fullWidth
                size={"medium"}
                required
                variant={"outlined"}
                InputProps={{
                  classes: { input: classes.input1 },
                  endAdornment: (
                    <muc.InputAdornment position="end">
                      <mui.PersonIcon color={"secondary"} />
                    </muc.InputAdornment>
                  ),
                }}
              />
            </muc.Grid>
            <muc.Grid xs={12} sm={12} item>
              <muc.TextField
                id="email"
                name="email"
                value={email}
                onChange={onChange}
                type="email"
                label="Email"
                placeholder="Enter email"
                fullWidth
                size={"medium"}
                required
                variant={"outlined"}
                InputProps={{
                  classes: { input: classes.input1 },
                  endAdornment: (
                    <muc.InputAdornment position="end">
                      <mui.AlternateEmailIcon color={"secondary"} />
                    </muc.InputAdornment>
                  ),
                }}
              />
            </muc.Grid>
            <muc.Grid xs={12} sm={6} item>
              <muc.TextField
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={onChange}
                label="Password"
                placeholder="Enter Password"
                required
                fullWidth
                size={"medium"}
                variant={"outlined"}
                InputProps={{
                  classes: { input: classes.input1 },
                  endAdornment: (
                    <muc.InputAdornment position="end">
                      <mui.KeyIcon color={"secondary"} />
                    </muc.InputAdornment>
                  ),
                }}
              />
            </muc.Grid>{" "}
            <muc.Grid xs={12} sm={6} item>
              <muc.TextField
                id="password2"
                name="password2"
                type="password"
                value={password2}
                onChange={onChange}
                label="Confirm Password"
                placeholder="Confirm Password"
                required
                fullWidth
                size={"medium"}
                variant={"outlined"}
                InputProps={{
                  classes: { input: classes.input1 },
                  endAdornment: (
                    <muc.InputAdornment position="end">
                      <mui.KeyIcon color={"secondary"} />
                    </muc.InputAdornment>
                  ),
                }}
              />
            </muc.Grid>
          </muc.Grid>

          <muc.Box mt={3}>
            <muc.Divider variant={"fullWidth"} />
          </muc.Box>
          <muc.Grid container spacing={1} mt={1}>
            <muc.Grid item xs={12} align="right">
              <muc.Button
                type="submit"
                size={"large"}
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<mui.HowToRegIcon />}
              >
                Register
              </muc.Button>
            </muc.Grid>
          </muc.Grid>
        </form>
      </muc.Grid>
    </div>
  );
};

export default Register;
