import * as muc from "../../../shared/MUI-Shared-Imports";
import * as mui from "../../../shared/MUI-Shared-Icons";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout, auth_reset } from "../../../features/auth/authSlice";
import { contacts_reset } from "../../../features/contacts/contactSlice";

function Navbar() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout());
    dispatch(auth_reset());
    dispatch(contacts_reset());
    navigate("/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuHomeRender = () => {
    navigate("/");
    handleClose();
  };
  const menuLoginRender = () => {
    navigate("/login");
    handleClose();
  };
  const menuSignupRender = () => {
    navigate("/register");
    handleClose();
  };
  const menuContactsRender = () => {
    navigate("/contacts");
    handleClose();
  };
  const menuAboutRender = () => {
    navigate("/about");
    handleClose();
  };
  const menuLogoutRender = () => {
    onLogout();
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = muc.styled((props) => (
    <muc.Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(0),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },

      "& .MuiMenuItem-root": {
        "&:hover": {
          backgroundColor: "#cfd8dc",
          // color: "white",
        },
        "& .MuiSvgIcon-root": {
          fontSize: 28,

          color: "#ff5722",
          // color: theme.palette.error,
          marginRight: theme.spacing(1.5),
        },

        "&:active": {
          backgroundColor: muc.alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <muc.Box sx={{ flexGrow: 1 }}>
      <muc.AppBar
        position="sticky"
        color="primary"
        sx={{ bgcolor: "primary.dark" }}
      >
        <muc.Toolbar>
          <mui.AppsIcon
            style={{
              width: "42px",
              height: "42px",
              marginRight: "5px",
              marginLeft: "10px",
            }}
          />
          <muc.Box sx={{ flexGrow: 1 }} style={{ marginLeft: 8 }}>
            <muc.Typography variant="h5"> Material UI</muc.Typography>
          </muc.Box>
          <muc.Hidden mdDown>
            <muc.Button
              LinkComponent={NavLink}
              to="/"
              color="inherit"
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "white",
                    }
                  : { color: "#C0C0C0" }
              }
              startIcon={<mui.HomeIcon />}
            >
              Home
            </muc.Button>
            <muc.Button
              color="inherit"
              LinkComponent={NavLink}
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "white",
                    }
                  : { color: "#C0C0C0" }
              }
              to="/contacts"
              startIcon={<mui.PermContactCalendarIcon />}
            >
              Contacts
            </muc.Button>
            <muc.Button
              color="inherit"
              LinkComponent={NavLink}
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "white",
                    }
                  : { color: "#C0C0C0" }
              }
              to="/about"
              startIcon={<mui.InfoIcon />}
            >
              About
            </muc.Button>
            {user ? (
              <muc.Button
                color="inherit"
                onClick={onLogout}
                LinkComponent={NavLink}
                style={({ isActive }) =>
                  isActive
                    ? {
                        color: "white",
                      }
                    : { color: "#C0C0C0" }
                }
                to="/login"
                startIcon={<mui.ExitToAppIcon />}
              >
                Logout
              </muc.Button>
            ) : (
              <>
                <muc.Button
                  color="inherit"
                  LinkComponent={NavLink}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "white",
                        }
                      : { color: "#C0C0C0" }
                  }
                  to="/login"
                  startIcon={<mui.LockOpenIcon />}
                >
                  Login
                </muc.Button>
                <muc.Button
                  color="inherit"
                  LinkComponent={NavLink}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "white",
                        }
                      : { color: "#C0C0C0" }
                  }
                  to="/register"
                  startIcon={<mui.HowToRegIcon />}
                >
                  SignUp
                </muc.Button>
              </>
            )}
          </muc.Hidden>
          <muc.Hidden mdUp>
            <mui.MenuIcon fontSize="medium" onClick={handleClick} />
          </muc.Hidden>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <muc.MenuItem onClick={menuHomeRender} disableRipple>
              <mui.HomeIcon /> Home
            </muc.MenuItem>
            <muc.MenuItem onClick={menuContactsRender} disableRipple>
              <mui.PermContactCalendarIcon /> Contacts
            </muc.MenuItem>
            <muc.MenuItem onClick={menuAboutRender} disableRipple>
              <mui.InfoIcon /> About
            </muc.MenuItem>
            {user ? (
              <muc.MenuItem onClick={menuLogoutRender} disableRipple>
                <mui.ExitToAppIcon /> Logout
              </muc.MenuItem>
            ) : (
              <muc.MenuItem onClick={menuLoginRender} disableRipple>
                <mui.LockOpenIcon /> Login
              </muc.MenuItem>
            )}
            <muc.Hidden>
              {user ? (
                <></>
              ) : (
                <muc.MenuItem onClick={menuSignupRender} disableRipple>
                  <mui.HowToRegIcon /> SignUp
                </muc.MenuItem>
              )}
            </muc.Hidden>
          </StyledMenu>
        </muc.Toolbar>
      </muc.AppBar>
    </muc.Box>
  );
}

export default Navbar;
