import * as muc from "../../shared/MUI-Shared-Imports";
import * as mui from "../../shared/MUI-Shared-Icons";
import { useParams } from "react-router-dom";

const EditContact = () => {
  const id = useParams().id;

  return (
    <muc.Grid
      style={{
        padding: "150px 5px 0 5px",
        width: 350,
        margin: "0 auto",
      }}
    >
      <muc.Grid container spacing={1}>
        <muc.Grid item xs={12} align="center" mb={2}>
          <mui.EditIcon
            color={"error"}
            style={{ width: "70px", height: "70px" }}
          />
          <muc.Typography variant="h4">Edit Contact : {id}</muc.Typography>
        </muc.Grid>
      </muc.Grid>
    </muc.Grid>
  );
};

export default EditContact;
