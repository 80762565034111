import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getContacts,
  deleteContact,
} from "../../features/contacts/contactSlice";
import Spinner from "../app-components/shared-components/Spinner";
import * as muc from "../../shared/MUI-Shared-Imports";
import * as mui from "../../shared/MUI-Shared-Icons";
import { useNavigate } from "react-router-dom";
import { blueGrey, blue, deepPurple, red, amber } from "@mui/material/colors";
import { setSnackbar } from "../../features/snackbar/Snackbar";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

const StyledTableCell = muc.styled(muc.TableCell)(({ theme }) => ({
  [`&.${muc.tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.warning.main,
    backgroundColor: blueGrey["600"],
    color: theme.palette.common.white,
  },
  [`&.${muc.tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = muc.makeStyles((theme) => ({
  table: {
    minWidth: 400,
  },
  tableRow: {
    "&:hover": {
      // backgroundColor: "teal[400] !important",
      backgroundColor: "#e0f2f1 !important",
    },
  },
  thead: {
    // backgroundColor: 'lightgray',
    fontWeight: "bolder",
    "& th:first-child": {
      borderRadius: ".25em 0 0 0em",
    },
    "& th:last-child": {
      borderRadius: "0 .25em 0em 0",
    },
  },
}));

const ContactsManager = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  let contactState = useSelector((store) => {
    return store["contact"];
  });

  useEffect(() => {
    dispatch(getContacts()); // dispatch action
    // const interval = setInterval(() => {
    //   dispatch(getContacts());
    // }, 10000);
    // return () => clearInterval(interval);
  }, [dispatch]);

  let { isLoading, isError, isSuccess, message, contacts } = contactState;

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    setSnackbar(
      true,
      "error",
      { message }
      // `Contact ID:${id.substr(-5)} deleted successfully`
    );
  }

  const onRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const onPageChange = (e, nextPage) => {
    setPage(nextPage);
  };
  const deleteContactFromDB = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteContact(id));
    dispatch(
      setSnackbar(
        true,
        "warning",
        `Contact deleted successfully`
        // `Contact ID:${id.substr(-5)} deleted successfully`
      )
    );
  };

  return (
    <muc.Paper
      elevation={1}
      sx={{
        margin: "0 auto",
        maxWidth: { xs: "95%", sm: "90%", md: "88%", lg: "85%", xl: "80%" },
        marginTop: 4,
        padding: 0,
      }}
    >
      <muc.TableContainer>
        <muc.Table>
          <muc.TableHead classes={{ root: classes.thead }}>
            <muc.TableRow>
              <StyledTableCell sx={{ width: 3 }}>ID#</StyledTableCell>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Gender</StyledTableCell>
              <StyledTableCell>Mobile#</StyledTableCell>
              <StyledTableCell>Landline#</StyledTableCell>
              <StyledTableCell>OFF-Address</StyledTableCell>
              <StyledTableCell>Home-Address</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </muc.TableRow>
          </muc.TableHead>
          <muc.TableBody>
            {!isLoading &&
              isSuccess &&
              contacts.length > 0 &&
              contacts
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((contact) => (
                  <muc.TableRow
                    key={contact._id}
                    hover
                    className={classes.tableRow}
                  >
                    <muc.TableCell>{contact._id.substr(-5)}</muc.TableCell>
                    <muc.TableCell>{contact.firstname}</muc.TableCell>
                    <muc.TableCell>{contact.lastname}</muc.TableCell>
                    <muc.TableCell>{contact.email}</muc.TableCell>
                    <muc.TableCell>{contact.gender}</muc.TableCell>
                    <muc.TableCell>{contact.mobile}</muc.TableCell>
                    <muc.TableCell>{contact.landline}</muc.TableCell>
                    <muc.TableCell>{contact.office_address}</muc.TableCell>
                    <muc.TableCell>{contact.home_address}</muc.TableCell>
                    <muc.TableCell>
                      <muc.IconButton
                        onClick={() =>
                          navigate("/contacts/edit/" + contact._id)
                        }
                      >
                        <mui.EditIcon color={"success"} />
                      </muc.IconButton>
                      <muc.IconButton
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure to delete this record?",
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              deleteContactFromDB(contact._id);
                            },
                          });
                        }}
                      >
                        <mui.DeleteForeverIcon color={"error"} />
                      </muc.IconButton>
                    </muc.TableCell>
                  </muc.TableRow>
                ))}
          </muc.TableBody>
        </muc.Table>
      </muc.TableContainer>
      <muc.TablePagination
        component={"div"}
        count={contacts.length}
        page={page}
        onRowsPerPageChange={onRowsPerPageChange}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 15, 25, 50, 100]}
        showFirstButton
        showLastButton
      />
      <Dialog open={confirmDialog.isOpen}>
        <DialogContent>
          <Typography variant="h6">{confirmDialog.title}</Typography>
          <Typography variant="subtitle2" color={"indigo"}>
            {confirmDialog.subTitle}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
          <muc.Button
            variant={"contained"}
            color={"error"}
            onClick={confirmDialog.onConfirm}
            size={"small"}
          >
            Yes
          </muc.Button>
          <muc.Button
            variant={"contained"}
            color={"primary"}
            size={"small"}
            onClick={() =>
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }
          >
            No
          </muc.Button>
        </DialogActions>
      </Dialog>
    </muc.Paper>
  );
};

export default ContactsManager;
