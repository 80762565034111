import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import contactReducer from "../features/contacts/contactSlice";
import snackbarReducer from "../features/snackbar/Snackbar";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    contact: contactReducer,
    snackbar: snackbarReducer,
  },
});
