import * as muc from "../../../shared/MUI-Shared-Imports";

const Spinner = () => {
  return (
    <muc.Grid
      style={{
        padding: "150px 5px 0 5px",
        width: 350,
        margin: "0 auto",
      }}
    >
      <muc.Grid container spacing={1}>
        <muc.Grid item xs={12} align="center" mb={2}>
          <muc.CircularProgress />
        </muc.Grid>
      </muc.Grid>
    </muc.Grid>
  );
};

export default Spinner;
