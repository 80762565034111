import * as muc from "../../../shared/MUI-Shared-Imports";
import * as mui from "../../../shared/MUI-Shared-Icons";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login, auth_reset } from "../../../features/auth/authSlice";
import Spinner from "../shared-components/Spinner";

const useStyles = muc.makeStyles(() => ({
  input1: {
    height: 17,
  },
  input2: {
    height: 50,
  },
}));

const Login = () => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const { email, password, showPassword } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setFormData({
      ...formData,
      showPassword: !formData.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      console.error(message);
    }

    if (isSuccess || user) {
      navigate("/");
    }

    dispatch(auth_reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    dispatch(login(userData));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <muc.Grid
        style={{
          padding: "50px 5px 0 5px",
          width: 350,
          margin: "0 auto",
        }}
      >
        <muc.Grid container spacing={1}>
          <muc.Grid item xs={12} align="center" mb={2}>
            <mui.HowToRegIcon
              color={"primary"}
              style={{ width: "70px", height: "70px" }}
            />
            <muc.Typography variant="h5" color="error">
              Login
            </muc.Typography>
          </muc.Grid>
        </muc.Grid>

        <form onSubmit={onSubmit}>
          <muc.Grid container spacing={2}>
            <muc.Grid xs={12} sm={12} item>
              <muc.TextField
                name="email"
                type="email"
                onChange={onChange}
                label="Email"
                value={email}
                placeholder="Enter email"
                fullWidth
                required
                variant={"outlined"}
                InputProps={{
                  classes: { input: classes.input1 },
                  endAdornment: (
                    <muc.InputAdornment position="end">
                      <mui.AlternateEmailIcon color={"secondary"} />
                    </muc.InputAdornment>
                  ),
                }}
              />
            </muc.Grid>
            <muc.Grid xs={12} sm={12} item>
              <muc.FormControl style={{ width: "100%" }} variant="outlined">
                <muc.InputLabel htmlFor="outlined-adornment-password">
                  Password
                </muc.InputLabel>
                <muc.OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  fullWidth
                  className={classes.input2}
                  name="password"
                  placeholder="Enter password"
                  required
                  onChange={handleChange("password")}
                  endAdornment={
                    <muc.InputAdornment position="end">
                      <muc.IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <mui.VisibilityOff />
                        ) : (
                          <mui.Visibility />
                        )}
                      </muc.IconButton>
                    </muc.InputAdornment>
                  }
                  label="Password"
                />
              </muc.FormControl>
            </muc.Grid>
          </muc.Grid>
          <muc.Grid container spacing={1} mt={2} mb={1}>
            <muc.Grid item xs={12} align="center">
              <muc.Button
                type="submit"
                size={"large"}
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<mui.LockOpenIcon />}
                style={{ marginBottom: "15px" }}
              >
                Login
              </muc.Button>
              <muc.Button
                startIcon={<mui.HowToRegIcon />}
                size={"large"}
                variant="contained"
                color={"success"}
                fullWidth
                LinkComponent={NavLink}
                to="/register"
              >
                Signup
              </muc.Button>
            </muc.Grid>
          </muc.Grid>
        </form>
      </muc.Grid>
    </div>
  );
};

export default Login;
