import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  getContacts,
  deleteContact,
} from "../../features/contacts/contactSlice";
import Spinner from "../app-components/shared-components/Spinner";
import * as muc from "../../shared/MUI-Shared-Imports";
import * as mui from "../../shared/MUI-Shared-Icons";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "../../features/snackbar/Snackbar";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

const CManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const contactState = useSelector((store) => {
    return store["contact"];
  });

  useEffect(() => {
    if (contactState.contacts.length <= 0) {
      dispatch(getContacts());
    }
  }, [dispatch]);

  let { isLoading, isError, isSuccess, message, contacts } = contactState;

  if (isLoading) {
    return <Spinner />;
  }
  if (isError) {
    setSnackbar(
      true,
      "error",
      { message }
      // `Contact ID:${id.substr(-5)} deleted successfully`
    );
  }
  const deleteContactFromDB = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteContact(id));
    dispatch(
      setSnackbar(
        true,
        "warning",
        `Contact deleted successfully`
        // `Contact ID:${id.substr(-5)} deleted successfully`
      )
    );
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              padding: "25px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
  });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "firstname",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastname",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "Mobile#",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "landline",
      label: "Landline#",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "office_address",
      label: "Office-Address",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "home_address",
      label: "Home-Address",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <muc.Tooltip title="View Contact">
                <muc.IconButton
                  onClick={() =>
                    navigate("/contacts/view/" + tableMeta.rowData[0])
                  }
                >
                  <mui.Visibility color={"primary"} />
                </muc.IconButton>
              </muc.Tooltip>
              <muc.Tooltip title="Edit Contact">
                <muc.IconButton
                  onClick={() =>
                    navigate("/contacts/edit/" + tableMeta.rowData[0])
                  }
                >
                  <mui.EditIcon color={"warning"} />
                </muc.IconButton>
              </muc.Tooltip>
              <muc.Tooltip title="Delete Contact">
                <muc.IconButton
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this record?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        deleteContactFromDB(tableMeta.rowData[0]);
                      },
                    });
                  }}
                >
                  <mui.DeleteForeverIcon color={"error"} />
                </muc.IconButton>
              </muc.Tooltip>
            </>
          );
        },
      },
    },
  ];

  const HeaderElements = () => (
    <>
      <muc.Tooltip title="Add Contact">
        <muc.IconButton onClick={() => navigate("/contacts/add")}>
          <mui.AddCircleIcon
            color={"warning"}
            sx={{ fontSize: { xs: 26, sm: 32 } }}
          />
        </muc.IconButton>
      </muc.Tooltip>
    </>
  );

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",
    // tableBodyHeight,
    // tableBodyMaxHeight,
    onTableChange: (action, state) => {
      // console.log(action);
      // console.dir(state);
    },
    rowsPerPage: 5,
    resizableColumns: false,
    rowsPerPageOptions: [5, 10, 15, 25, 50, 100, 150],
    jumpToPage: true,
    showLastButton: true,
    elevation: 2,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total Per Page",
        displayRows: "of",
        jumpToPage: "Page",
      },
      body: {
        noMatch: "No Contacts to display please Add Contact",
      },
    },
    selectableRows: "none",
    customToolbar: () => <HeaderElements />,
  };

  return (
    <muc.Box
      sx={{
        width: { xs: "98%", sm: "95%", md: "90%", lg: "85%", xl: "80%" },
        margin: "auto auto",
        marginTop: "1.5%",
        marginBottom: "2%",
      }}
    >
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          {isSuccess && contacts && (
            <MUIDataTable
              title={"Contacts Manager"}
              data={contacts}
              columns={columns}
              options={options}
            />
          )}
        </ThemeProvider>
      </CacheProvider>

      <Dialog open={confirmDialog.isOpen}>
        <DialogContent>
          <Typography variant="h6">{confirmDialog.title}</Typography>
          <Typography variant="subtitle2" color={"indigo"}>
            {confirmDialog.subTitle}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
          <muc.Button
            variant={"contained"}
            color={"error"}
            onClick={confirmDialog.onConfirm}
            size={"small"}
          >
            Yes
          </muc.Button>
          <muc.Button
            variant={"contained"}
            color={"primary"}
            size={"small"}
            onClick={() =>
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }
          >
            No
          </muc.Button>
        </DialogActions>
      </Dialog>
    </muc.Box>
  );
};

export default CManager;
